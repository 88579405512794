
import { Darkmode } from '../../features/Redux/counter/counterSlice';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const LightModeBTN = () => {
    const dispatch = useDispatch()
    const lightModeBTN = useSelector((state:any) => state.counter.value)
    const click = (e:any) => {
        e.stopPropagation()
        const target = e.target.getBoundingClientRect()
        dispatch(Darkmode([!lightModeBTN[0], target.top, target.left]))
    };
    useEffect(() => {

    }, [])
    return (
        <div onClick={click} id='lightModeBTN' className='lightModeBTN w-8 h-8 flex justify-center items-center dark:text-white rounded-full dark:bg-gray-600 bg-gray-200'>
            {lightModeBTN[0] ?
                <svg className='w-5 h-5 ' fill="none" stroke="currentColor" strokeWidth={1.5} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 3v2.25m6.364.386l-1.591 1.591M21 12h-2.25m-.386 6.364l-1.591-1.591M12 18.75V21m-4.773-4.227l-1.591 1.591M5.25 12H3m4.227-4.773L5.636 5.636M15.75 12a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z" />
                </svg>
                :
                <svg className='w-5 h-5 ' fill="none" stroke="currentColor" strokeWidth={1.5} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M21.752 15.002A9.718 9.718 0 0118 15.75c-5.385 0-9.75-4.365-9.75-9.75 0-1.33.266-2.597.748-3.752A9.753 9.753 0 003 11.25C3 16.635 7.365 21 12.75 21a9.753 9.753 0 009.002-5.998z" />
                </svg>
            }
        </div>
    );
}

export default LightModeBTN;